<!-- Start Settings content -->
<div>
  <div class="px-4 pt-4">
    <h4 class="mb-0">{{'chat.tabs.settings.title' | translate}}</h4>
  </div>

  <div class="text-center border-bottom p-4">
    <div class="mb-4 profile-user">
      <img src="{{senderProfile}}" class="rounded-circle avatar-lg img-thumbnail" alt="" id="user_profile">
      <input id="profile-img-file-input" type="file" class="profile-img-file-input d-none" (change)="fileChange($event)">
      <label for="profile-img-file-input" class="profile-photo-edit avatar-xs">
        <span class="avatar-title rounded-circle bg-light text-body">
          <i class="ri-pencil-fill"></i>
        </span>
      </label>
    </div>

    <h5 class="font-size-16 mb-1 text-truncate">{{senderName | translate}}</h5>
    <div class="dropdown d-inline-block mb-1" ngbDropdown>
      <a class="text-muted dropdown-toggle pb-1 d-block" href="javascript: void(0);" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" ngbDropdownToggle>
        {{'chat.tabs.settings.status.text' | translate}} <i class="mdi mdi-chevron-down"></i>
      </a>

      <div class="dropdown-menu" ngbDropdownMenu>
        <a class="dropdown-item" href="javascript:void(0);">{{'chat.tabs.settings.status.dropdown.available' |
          translate}}</a>
        <a class="dropdown-item" href="javascript:void(0);">{{'chat.tabs.settings.status.dropdown.busy' |
          translate}}</a>
      </div>
    </div>
  </div>
  <!-- End profile user -->

  <!-- Start User profile description -->
  <ngx-simplebar class="p-4 user-profile-desc" data-simplebar>
    <div ngbAccordion #acc="ngbAccordion" activeIds="perfonal-info" [closeOthers]="true">
      <div ngbAccordionItem cardClass="card shadow-none border mb-2" id="perfonal-info" [collapsed]="false">
        <div ngbAccordionHeader>
          <h5 class="font-size-14 m-0">
            <button ngbAccordionButton> {{'chat.tabs.settings.info.title' | translate}}</button>
          </h5>
        </div>
        <div ngbAccordionCollapse>
          <div ngbAccordionBody>
            <div class="float-end">
              <button type="button" class="btn btn-light btn-sm" id="user_name_edit" (click)="edit_userName()"><i class="ri-edit-fill me-1 ms-0 align-middle"></i>
                {{'chat.tabs.settings.info.edit' | translate}}</button>
            </div>
            <div>
              <p class="text-muted mb-1">{{'chat.tabs.settings.info.name.text' | translate}}</p>
              <h5 class="font-size-14" id="user_name">{{senderName | translate}}</h5>
              <div id="edit-user-name" class="visually-hidden d-flex justify-content-between">
                <input type="text" [(ngModel)]="playerName" class="form-control bg-soft-light border-light" maxlength="20" />
                <div class="float-right">
                  <button type="submit" id="receiverSave" (click)="userNameChange()" class="btn btn-primary btn-block waves-effect waves-light" style="display: block;">
                    Save</button>
                </div>
              </div>
            </div>

            <div class="mt-4">
              <p class="text-muted mb-1">{{'chat.tabs.settings.info.email.text' | translate}}</p>
              <h5 class="font-size-14">{{'chat.tabs.settings.info.email.value' | translate}}</h5>
            </div>

            <div class="mt-4">
              <p class="text-muted mb-1">{{'chat.tabs.settings.info.time.text' | translate}}</p>
              <h5 class="font-size-14">{{'chat.tabs.settings.info.time.value' | translate}}</h5>
            </div>

            <div class="mt-4">
              <p class="text-muted mb-1">{{'chat.tabs.settings.info.location.text' | translate}}</p>
              <h5 class="font-size-14">{{'chat.tabs.settings.info.location.value' | translate}}</h5>
            </div>
          </div>
        </div>
      </div>
      <div ngbAccordionItem cardClass="card shadow-none border mb-2" id="privacy">
        <div ngbAccordionHeader>
          <h5 class="font-size-14 m-0">
            <button ngbAccordionButton>
              {{'chat.tabs.settings.privacy.title' | translate}}
            </button>
          </h5>
        </div>
        <div ngbAccordionCollapse>
          <div ngbAccordionBody>
            <div class="py-3">
              <div class="d-flex align-items-center">
                <div class="flex-grow-1 overflow-hidden">
                  <h5 class="font-size-13 mb-0 text-truncate">{{'chat.tabs.settings.privacy.photo' | translate}}</h5>

                </div>
                <div class="dropdown ms-2 me-0" ngbDropdown>
                  <button class="btn btn-light btn-sm dropdown-toggle w-sm" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" ngbDropdownToggle>
                    {{'chat.tabs.settings.privacy.dropdowntext' | translate}} <i class="mdi mdi-chevron-down"></i>
                  </button>
                  <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
                    <a class="dropdown-item" href="javascript:void(0);">{{'chat.tabs.settings.privacy.dropdown.everyone' |
                      translate}}</a>
                    <a class="dropdown-item" href="javascript:void(0);">{{'chat.tabs.settings.privacy.dropdown.selected' |
                      translate}}</a>
                    <a class="dropdown-item" href="javascript:void(0);">{{'chat.tabs.settings.privacy.dropdown.nobody' |
                      translate}}</a>
                  </div>
                </div>
              </div>
            </div>
            <div class="py-3 border-top">
              <div class="d-flex align-items-center">
                <div class="flex-grow-1 overflow-hidden">
                  <h5 class="font-size-13 mb-0 text-truncate">{{'chat.tabs.settings.privacy.seen' | translate}}</h5>

                </div>
                <div class="ms-2 me-0">
                  <div class="form-check form-switch">
                    <input type="checkbox" class="form-check-input" id="privacy-lastseenSwitch" checked="">
                    <label class="form-check-label" for="privacy-lastseenSwitch"></label>
                  </div>
                </div>
              </div>
            </div>

            <div class="py-3 border-top">
              <div class="d-flex align-items-center">
                <div class="flex-grow-1 overflow-hidden">
                  <h5 class="font-size-13 mb-0 text-truncate">{{'chat.tabs.settings.privacy.status' | translate}}</h5>
                </div>
                <div class="dropdown ms-2 me-0" ngbDropdown>
                  <button class="btn btn-light btn-sm dropdown-toggle w-sm" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" ngbDropdownToggle>
                    {{'chat.tabs.settings.privacy.dropdowntext' | translate}} <i class="mdi mdi-chevron-down"></i>
                  </button>
                  <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
                    <a class="dropdown-item" href="javascript:void(0);">{{'chat.tabs.settings.privacy.dropdown.everyone' |
                      translate}}</a>
                    <a class="dropdown-item" href="javascript:void(0);">{{'chat.tabs.settings.privacy.dropdown.selected' |
                      translate}}</a>
                    <a class="dropdown-item" href="javascript:void(0);">{{'chat.tabs.settings.privacy.dropdown.nobody' |
                      translate}}</a>
                  </div>
                </div>
              </div>
            </div>

            <div class="py-3 border-top">
              <div class="d-flex align-items-center">
                <div class="flex-grow-1 overflow-hidden">
                  <h5 class="font-size-13 mb-0 text-truncate">{{'chat.tabs.settings.privacy.receipt' | translate}}</h5>

                </div>
                <div class="ms-2 me-0">
                  <div class="form-check form-switch">
                    <input type="checkbox" class="form-check-input" id="privacy-readreceiptSwitch" checked="">
                    <label class="form-check-label" for="privacy-readreceiptSwitch"></label>
                  </div>
                </div>
              </div>
            </div>

            <div class="py-3 border-top">
              <div class="d-flex align-items-center">
                <div class="flex-grow-1 overflow-hidden">
                  <h5 class="font-size-13 mb-0 text-truncate">{{'chat.tabs.settings.privacy.groups' | translate}}</h5>

                </div>
                <div class="dropdown ms-2 me-0" ngbDropdown>
                  <button class="btn btn-light btn-sm dropdown-toggle w-sm" ngbDropdownToggle type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    {{ 'chat.tabs.settings.privacy.dropdowntext' | translate}} <i class="mdi mdi-chevron-down"></i>
                  </button>
                  <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
                    <a class="dropdown-item" href="javascript:void(0);">{{'chat.tabs.settings.privacy.dropdown.everyone' |
                      translate}}</a>
                    <a class="dropdown-item" href="javascript:void(0);">{{'chat.tabs.settings.privacy.dropdown.selected' |
                      translate}}</a>
                    <a class="dropdown-item" href="javascript:void(0);">{{'chat.tabs.settings.privacy.dropdown.nobody' |
                      translate}}</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div ngbAccordionItem cardClass="card shadow-none border mb-2" id="security">
        <div ngbAccordionHeader>
          <h5 class="font-size-14 m-0">
            <button ngbAccordionButton>
              {{'chat.tabs.settings.security.title' | translate}}
            </button>
          </h5>
        </div>
        <div ngbAccordionCollapse>
          <div ngbAccordionBody>
            <div class="d-flex align-items-center">
              <div class="flex-grow-1 overflow-hidden">
                <h5 class="font-size-13 mb-0 text-truncate">{{'chat.tabs.settings.security.text' | translate}}</h5>
              </div>
              <div class="ms-2 me-0">
                <div class="form-check form-switch">
                  <input type="checkbox" class="form-check-input" id="security-notificationswitch">
                  <label class="form-check-label" for="security-notificationswitch"></label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div ngbAccordionItem cardClass="card shadow-none border mb-2" id="help">
        <div ngbAccordionHeader>
          <h5 class="font-size-14 m-0">
            <button ngbAccordionButton>
              {{'chat.tabs.settings.help.title' | translate}}
            </button>
          </h5>
        </div>
        <div ngbAccordionCollapse>
          <div ngbAccordionBody>
            <div class="py-3">
              <h5 class="font-size-13 mb-0"><a href="javascript:void(0);" class="text-body d-block">{{'chat.tabs.settings.help.faqs' | translate}}</a>
              </h5>
            </div>
            <div class="py-3 border-top">
              <h5 class="font-size-13 mb-0"><a href="javascript:void(0);" class="text-body d-block">{{'chat.tabs.settings.help.contact' | translate}}</a></h5>
            </div>
            <div class="py-3 border-top">
              <h5 class="font-size-13 mb-0"><a href="javascript:void(0);" class="text-body d-block">{{'chat.tabs.settings.help.policy' | translate}}</a>
              </h5>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end profile-setting-accordion -->
  </ngx-simplebar>
  <!-- End User profile description -->
</div>
<!-- Start Settings content -->