<!-- Start profile content -->
<div>
  <div class="px-4 pt-4">
    <div class="user-chat-nav float-end">
      <div class="dropdown" ngbDropdown>
        <a href="javascript: void(0);" class="font-size-18 text-muted dropdown-toggle" ngbDropdownToggle data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <i class="ri-more-2-fill"></i>
        </a>
        <div class="dropdown-menu dropdown-menu-right" ngbDropdownMenu>
          <a class="dropdown-item" href="javascript:void(0);">{{'chat.tabs.profile.dropdown.edit' | translate}}</a>
          <a class="dropdown-item" href="javascript:void(0);">{{'chat.tabs.profile.dropdown.action' | translate}}</a>
          <div class="dropdown-divider"></div>
          <a class="dropdown-item" href="javascript:void(0);">{{'chat.tabs.profile.dropdown.another' | translate}}n</a>
        </div>
      </div>
    </div>
    <h4 class="mb-0">{{'chat.tabs.profile.title' | translate }}</h4>
  </div>

  <div class="text-center p-4 border-bottom">
    <div class="mb-4">
      <img src="{{senderProfile}}" class="rounded-circle avatar-lg img-thumbnail" alt="">
    </div>

    <h5 class="font-size-16 mb-1 text-truncate">{{senderName | translate}}</h5>
    <p class="text-muted text-truncate mb-1"><i class="ri-record-circle-fill font-size-10 text-success me-1 d-inline-block"></i>
      {{'chat.tabs.profile.status' | translate}}</p>
  </div>
  <!-- End profile user -->

  <!-- Start user-profile-desc -->
  <ngx-simplebar class="p-4 user-profile-desc">

    <div class="text-muted">
      <p class="mb-4">{{'chat.tabs.profile.message'| translate}}</p>
    </div>

    <div ngbAccordion #acc="ngbAccordion" activeIds="about" [closeOthers]="true">
      <div ngbAccordionItem cardClass="card shadow-none border mb-2" id="about" [collapsed]="false">
        <div ngbAccordionHeader>
          <button ngbAccordionButton class="font-size-14 m-0">
            <i class="ri-user-2-line me-2 align-middle d-inline-block"></i>
            {{'chat.tabs.profile.about.text' | translate}}
          </button>
        </div>
        <div ngbAccordionCollapse>
          <div ngbAccordionBody>
            <div>
              <p class="text-muted mb-1">{{ 'chat.tabs.profile.about.name.text' | translate}}</p>
              <h5 class="font-size-14">{{ senderName | translate}}</h5>
            </div>

            <div class="mt-4">
              <p class="text-muted mb-1">{{ 'chat.tabs.profile.about.email.text' | translate}}</p>
              <h5 class="font-size-14">{{ 'chat.tabs.profile.about.email.value' | translate}}</h5>
            </div>

            <div class="mt-4">
              <p class="text-muted mb-1">{{ 'chat.tabs.profile.about.time.text' | translate}}</p>
              <h5 class="font-size-14">{{ 'chat.tabs.profile.about.time.value' | translate}}</h5>
            </div>

            <div class="mt-4">
              <p class="text-muted mb-1">{{ 'chat.tabs.profile.about.location.text' | translate}}</p>
              <h5 class="font-size-14 mb-0">{{ 'chat.tabs.profile.about.location.value' | translate}}</h5>
            </div>
          </div>
        </div>
      </div>
      <div ngbAccordionItem cardClass="card shadow-none border mb-2" id="file">
        <div ngbAccordionHeader>
          <button ngbAccordionButton class="font-size-14 m-0">
            <i class="ri-attachment-line me-2 align-middle d-inline-block"></i>
            {{'chat.tabs.profile.attachedfile.text' | translate}}
          </button>
        </div>
        <div ngbAccordionCollapse>
          <div ngbAccordionBody>
            <div class="card p-2 border mb-2">
              <div class="d-flex align-items-center">
                <div class="avatar-sm me-3 ms-0">
                  <div class="avatar-title bg-primary-subtle text-primary rounded font-size-20">
                    <i class="ri-file-text-fill"></i>
                  </div>
                </div>
                <div class="flex-grow-1">
                  <div class="text-start">
                    <h5 class="font-size-14 mb-1">{{'chat.tabs.profile.attachedfile.file1.name' | translate}}</h5>
                    <p class="text-muted font-size-13 mb-0">{{'chat.tabs.profile.attachedfile.file1.size' | translate}}
                    </p>
                  </div>
                </div>
                <div class="ms-4 me-0">
                  <ul class="list-inline mb-0 font-size-18">
                    <li class="list-inline-item">
                      <a href="javascript:void(0);" class="text-muted px-1">
                        <i class="ri-download-2-line"></i>
                      </a>
                    </li>
                    <li class="list-inline-item dropdown" ngbDropdown>
                      <a class="dropdown-toggle text-muted px-1" href="javascript:void(0);" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" ngbDropdownToggle>
                        <i class="ri-more-fill"></i>
                      </a>
                      <div class="dropdown-menu dropdown-menu-right" ngbDropdownMenu>
                        <a class="dropdown-item" href="javascript:void(0);">{{'chat.tabs.profile.attachedfile.dropdown.action' | translate}}</a>
                        <a class="dropdown-item" href="javascript:void(0);">{{'chat.tabs.profile.attachedfile.dropdown.another' | translate}}</a>
                        <div class="dropdown-divider"></div>
                        <a class="dropdown-item" href="javascript:void(0);">{{'chat.tabs.profile.attachedfile.dropdown.delete' | translate}}</a>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <!-- end card -->

            <div class="card p-2 border mb-2">
              <div class="d-flex align-items-center">
                <div class="avatar-sm me-3 ms-0">
                  <div class="avatar-title bg-primary-subtle text-primary rounded font-size-20">
                    <i class="ri-image-fill"></i>
                  </div>
                </div>
                <div class="flex-grow-1">
                  <div class="text-start">
                    <h5 class="font-size-14 mb-1">{{'chat.tabs.profile.attachedfile.file2.name' | translate}}</h5>
                    <p class="text-muted font-size-13 mb-0">{{'chat.tabs.profile.attachedfile.file2.size' | translate}}
                    </p>
                  </div>
                </div>
                <div class="ms-4 me-0">
                  <ul class="list-inline mb-0 font-size-18">
                    <li class="list-inline-item">
                      <a href="javascript:void(0);" class="text-muted px-1">
                        <i class="ri-download-2-line"></i>
                      </a>
                    </li>
                    <li class="list-inline-item dropdown" ngbDropdown>
                      <a class="dropdown-toggle text-muted px-1" href="javascript: void(0);" ngbDropdownToggle role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i class="ri-more-fill"></i>
                      </a>
                      <div class="dropdown-menu dropdown-menu-right" ngbDropdownMenu>
                        <a class="dropdown-item" href="javascript:void(0);">{{'chat.tabs.profile.attachedfile.dropdown.action' | translate}}</a>
                        <a class="dropdown-item" href="javascript:void(0);">{{'chat.tabs.profile.attachedfile.dropdown.another' | translate}}</a>
                        <div class="dropdown-divider"></div>
                        <a class="dropdown-item" href="javascript:void(0);">{{'chat.tabs.profile.attachedfile.dropdown.delete' | translate}}</a>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <!-- end card -->

            <div class="card p-2 border mb-2">
              <div class="d-flex align-items-center">
                <div class="avatar-sm me-3 ms-0">
                  <div class="avatar-title bg-primary-subtle text-primary rounded font-size-20">
                    <i class="ri-image-fill"></i>
                  </div>
                </div>
                <div class="flex-grow-1">
                  <div class="text-start">
                    <h5 class="font-size-14 mb-1">{{'chat.tabs.profile.attachedfile.file3.name' | translate}}</h5>
                    <p class="text-muted font-size-13 mb-0">{{'chat.tabs.profile.attachedfile.file3.size' | translate}}
                    </p>
                  </div>
                </div>
                <div class="ms-4 me-0">
                  <ul class="list-inline mb-0 font-size-18">
                    <li class="list-inline-item">
                      <a href="javascript:void(0);" class="text-muted px-1">
                        <i class="ri-download-2-line"></i>
                      </a>
                    </li>
                    <li class="list-inline-item dropdown" ngbDropdown>
                      <a class="dropdown-toggle text-muted px-1" href="javascript:void(0);" ngbDropdownToggle role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i class="ri-more-fill"></i>
                      </a>
                      <div class="dropdown-menu dropdown-menu-right" ngbDropdownMenu>
                        <a class="dropdown-item" href="javascript:void(0);">{{'chat.tabs.profile.attachedfile.dropdown.action' | translate}}</a>
                        <a class="dropdown-item" href="javascript:void(0);">{{'chat.tabs.profile.attachedfile.dropdown.another' | translate}}</a>
                        <div class="dropdown-divider"></div>
                        <a class="dropdown-item" href="javascript:void(0);">{{'chat.tabs.profile.attachedfile.dropdown.delete' | translate}}</a>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <!-- end card -->

            <div class="card p-2 border mb-2">
              <div class="d-flex align-items-center">
                <div class="avatar-sm me-3 ms-0">
                  <div class="avatar-title bg-primary-subtle text-primary rounded font-size-20">
                    <i class="ri-file-text-fill"></i>
                  </div>
                </div>
                <div class="flex-grow-1">
                  <div class="text-start">
                    <h5 class="font-size-14 mb-1">{{'chat.tabs.profile.attachedfile.file4.name' | translate}}</h5>
                    <p class="text-muted font-size-13 mb-0">{{'chat.tabs.profile.attachedfile.file4.size' | translate}}
                    </p>
                  </div>
                </div>
                <div class="ms-4 me-0">
                  <ul class="list-inline mb-0 font-size-18">
                    <li class="list-inline-item">
                      <a href="javascript:void(0);" class="text-muted px-1">
                        <i class="ri-download-2-line"></i>
                      </a>
                    </li>
                    <li class="list-inline-item dropdown" ngbDropdown>
                      <a class="dropdown-toggle text-muted px-1" href="javascript:void(0);" ngbDropdownToggle role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i class="ri-more-fill"></i>
                      </a>
                      <div class="dropdown-menu dropdown-menu-right" ngbDropdownMenu>
                        <a class="dropdown-item" href="javascript:void(0);">{{'chat.tabs.profile.attachedfile.dropdown.action' | translate}}</a>
                        <a class="dropdown-item" href="javascript:void(0);">{{'chat.tabs.profile.attachedfile.dropdown.another' | translate}}</a>
                        <div class="dropdown-divider"></div>
                        <a class="dropdown-item" href="javascript:void(0);">{{'chat.tabs.profile.attachedfile.dropdown.delete' | translate}}</a>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <!-- end card -->
          </div>
        </div>
      </div>
    </div>
    <!-- end profile-user-accordion -->

  </ngx-simplebar>
  <!-- end user-profile-desc -->
</div>
<!-- End profile content -->